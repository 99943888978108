@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
  
@layer base {
    @font-face {
        font-family: 'Wild World';
        src: local('Wild World'), url(./fonts/wild-world.otf) format('opentype');
    }
}
.MuiDialog-root{
    z-index: 10 !important;
}


.animate-fade-in-popup{
  animation: animate-popup ease .5s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-container::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* https://github.com/yairEO/fakescroll is a good candidate to check later */

html {
    --scrollbarBG: #1B1E21;
    --thumbBG: #101316;
}
.scrollable-container-with-scrollbar::-webkit-scrollbar {
    width: 11px; 
}
.scrollable-container-with-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    padding-left: 2px;
}
.scrollable-container-with-scrollbar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    width: 0;
    display: none;
}
.scrollable-container-with-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
    height: 20px;
}

.carousel-indicators .active[data-bs-target]{
    width: 30px;
    border-radius: 10px;
    height: 2px;
}

.carousel-indicators [data-bs-target]{
    width: 4px;
    border-radius: 50%;
    height: 4px;
}

.carousel-indicators {
    align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

[type="search"]::-webkit-search-cancel-button {
    filter: grayscale(1);
}

button,
input[type=button],
input[type="file" i]::-webkit-file-upload-button
{
    cursor: pointer;
}

*::-webkit-scrollbar {
    display: none;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#marquee{
  white-space: nowrap;
  overflow: hidden;
}

@keyframes animate{
  100%{
    transform: translate(-100%, 0);
  }
}
#marquee div{
  animation: animate 30s linear infinite;
  padding-left: 100%;
  display: inline-block;
}
#dialogoverlay{
	display: none;
	position: fixed;
    /* height: 100vh;
  overflow-y: hidden; */
	top: 0px;
    left: 0px;
	width: 100%;
    overflow: hidden;
    z-index: 100;
}

#dialogbox{
	display: none;
	position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    height: 150px;
    opacity: 100%;
	width:550px;
   
	z-index: 1000;
}
#dialogbox > div{ background:#1B1E21; height: 150px; z-index: inherit; border-radius: 4px
; }
#dialogbox > #dialogboxbody{font-size:15px; padding:13px; color:#fff; }
#dialogbox > #dialogboxbutton{ background-color: #7c94dc; font-size:15px; width: 75px; height: 38px; right:20px; position: absolute; bottom:20px; color:black; border-radius: 4px; }

/* #nftIssue {
    background: url(/public/downArrow.svg) no-repeat !important;
    width: 100%;
    background-position: absolute right 1rem center;
    background-size: 1em;
} */

/* #nftImage {
@apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover;
} */

.activeButton {
  background-color: #2DC79D;
}

/* disabled, */
.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.price{
  margin-left: -14px;
}

 .dateTab{
  margin-left: -24px;
}

.fromTab{
  margin-left: -14px;
}

.toTab{
    margin-left: -14px;

}

@keyframes animate-popup {
  from {   
    opacity: 0; transform: scale(0.5);
  }
  to {
    opacity: 1; transform: scale(1);
  }
}