/* Hamburger menu css */
  #nav-icon{
    width: 60px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 0.25rem;
    width: 50%;
    background: #FFF;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #nav-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  
  #nav-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  
  #nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 0px;
  }
  
  #nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 18px;
  }
  
  #nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 36px;
  }
  
  #nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }
  
  #nav-icon.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }
  
  #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(5) {
    left: 5px;
    top: 29px;
  }
  
  #nav-icon.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }

  /* small screen menu css */
  .small-screen-menu-nav-item-1 {
    grid-area: nav1;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-2 {
    grid-area: nav2;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-3 {
    grid-area: nav3;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-4 {
    grid-area: nav4;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-5 {
    grid-area: nav5;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-6 {
    grid-area: nav6;
    justify-self: flex-start;
  }
  .small-screen-menu-nav-item-7 {
    grid-area: nav7;
    justify-self: flex-start;
  }
  
  .small-screen-menu-user-item-1 {
    grid-area: user1;
  }  
  .small-screen-menu-user-item-2 {
    grid-area: user2;
  }  
  .small-screen-menu-user-item-3 {
    grid-area: user3;
    justify-self: center;
  }  
  .small-screen-menu-user-item-4 {
    grid-area: user4;
    justify-self: center;
  }
  
  .small-screen-menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    "nav1 nav2 . user1"
    "nav3 nav4 . user2"
    "nav5 nav6 . user3"
    "nav7 nav8 . user3";
  }